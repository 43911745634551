.topContent{
    top:0;
    margin:0;
    display: block;
}
.appBar{
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    background-image: url("assets/Plataforma_Topo.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.loginAppBar{
    width: 100%;
    height: 150px;
    background-color: #FFFFFF;
    background-image: url("assets/Login_Topo.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.bottom{
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-size: cover;
}
.defaultBottom{
    background-image: url("assets/Plataforma_Rodape.png");
}
.loginBottom{
    background-image: url("assets/Login_Rodape.png");
}
.topMenu{
    gap: 8px;
    padding-inline: 32PX;
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    text-align: right;
    justify-content: right;
    display: flex;
    color: #4C338C;
}
.logoArea{
    margin-inline: auto;
    width: 100%;
    height: 40px;
    text-align: center;
}
.progress{
    height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: rgb(93, 188, 202);
}
.emptyState{
    display: flex;
    justify-content: center;
    align-items: center;
    height:20vh;
}
.emptyStateItem{
    width: 550px;
    display: block;
    text-align: center;
}
.mainBox{
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 16px;
}
.mainNoWrapBox{
    width: 100%;
    align-items: center;
    display: flex;
    gap: 16px;
}
.mainLogo{
    width: 100% !important;
    min-width: 100%;
    height: 80px;
    margin-bottom: 100px;
    display: block;
    background-image: url("assets/Login_Logo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.loginBox{
    width: 100%;
    align-items: center;
    justify-content: center;
    /* height: 80vh; */
    /* margin: 20vh 0; */
    display: block;
    gap: 16px;
}
.loginLogo{
    width: 100% !important;
    min-width: 100%;
    height: 80px;
    margin-bottom: 16px;
    display: block;
    background-image: url("assets/Login_Logo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.login{
    margin: 50px auto;
    width: 500px !important;
    gap: 32px;
}
.loginItem{
    width: 500px;
}

.ilineItems{
    /* display: flex; */
    /* flex-direction: row;*/
    gap: 16px; 
    justify-content: center;
    width: 100%;
}
.ilineItem{
    min-width: 600px;
    width: 600px;
}
.title{
    color: rgb(45, 105, 170);
    font-weight: bold !important;
}
.privacylink{
    color: rgb(45, 105, 170) !important;
    text-decoration-color: rgb(45, 105, 170) !important;
    font-weight: bold !important;
}
.remindmelink{
    color: rgb(93, 188, 202) !important;
    text-decoration-color: rgb(45, 105, 170) !important;
    font-weight: bold !important;
    text-decoration: none !important;
    cursor: pointer;
}
.errorPaper{
    justify-content: center;
    text-align: center;
    color: red !important;
    background-color: unset !important;
    padding: 16px;
}
.successPaper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:#FFFFFF !important;
    background-color:rgb(93, 188, 202)!important;
    width: 450px;
    padding: 16px;
    gap: 16px;
}
.successSinglePaper{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    border: 1px solid rgb(93, 188, 202);
    border-radius: 50px !important;
    width: 800px;
    padding: 100px;
    gap: 50px;
}
.legendPaper{
    display: inline-block;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    border: 1px solid rgb(93, 188, 202);
    border-radius: 20px !important;
    width: 100%;
    padding: 8px;
    margin-bottom: 32px;
}
.disclaimer{
    justify-content: center;
    align-items: center;
    text-align: justify;
    color:#000000 !important;
    background-color:rgb(191, 233, 239)!important;
    min-width: 100vw;
    padding: 16px 32px;
    margin-top: 50px;
    /* margin-bottom: -30px; */
    font-size: 10px;
    position: fixed;
    bottom: 50px;
}
.titleAMT{
    color: rgb(93, 188, 202);
    font-weight: bold !important;
}

.tableCellText{
    overflow:hidden;
    text-wrap:nowrap;
    text-overflow:ellipsis;
    max-width: 500px;
}
.pdfviewer{
    /* width:60vw; */
    min-width: 500px;
    height: calc(100vh - 220px);
    position: relative;
}
.overlayPdf{
    top:5vh;
    left:0;
    width:calc(100% - 12px);
    height:100%;
    position:absolute;
}
.topOverlayPdf{
    top:0;
    right:0;
    left: 26px;
    width: auto;
    height:5vh;
    position:absolute;
    background-color: rgb(50, 54, 57);
}
@media screen and (max-resolution: 144dpi){
    .topOverlayPdf{
        left: 32px;
    }
}
@media screen and (max-resolution: 129dpi){
    .topOverlayPdf{
        left: 48px;
    }
}
@media screen and (max-resolution: 100dpi){
    .topOverlayPdf{
        left: 64px;
    }
}

.evaluationBox{
    display:block !important;
    min-width:450px;
    width:450px !important;
    height:calc(100vh - 220px); 
}
.evaluation{
    display:block !important;
    width:100%;
    height:calc(100vh - 370px);
    overflow-y: auto;
    padding: 0 10px;  
}
.evaluationButtonArea{
    display:block !important;
    width:100%;
    height: 150px; 
    max-height: 150px; 
    overflow-y: hidden; 
    position: relative; 
}
.bottomButton{
    position:absolute !important;
    max-height: 40px !important;
    bottom: 0;
}
.avaliateButton{
    width: 100%;
    min-width: 150px;
}
.detailButton{
    min-width: 110px !important;
}
.total{
    border-radius: 16px;
    border: 1px red solid;
    padding: 10px;
    text-align: center;
    margin: 16px 0 32px !important;
}

.admin{
    color: rgb(237, 50, 136);
    border-color: rgb(237, 50, 136);
    font-weight: bold !important;
}
.valueAdmin{
    color: black;
    margin: 24px 48px !important;
    display: flex;
}
.totalValueAdmin{
    color: rgb(146, 148, 151);
    align-self: flex-end;
    padding: 0 0 2px 5px;
}

.skeleton-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    align-content: space-between;
}

.skeleton{
    /* padding: 10px 10px 0 10px; */
    background-color: #515050;
    flex: 1;
    background-image: linear-gradient(130deg, rgb(234, 236, 238, 1) 20%, rgba(234, 236, 238, 0.876) 30%, rgba(234, 236, 238, 0.876) 35%, rgba(234, 236, 238,1) 40%);
    background-position: 0% 0;
    background-size: 300% 200%;
    color: rgba(0,0,0,0);
    animation: shimmer 2s infinite;
}

.skeleton-ball{
    border-radius: 50px;
    height: 60px;
    width: 60px;
}
.skeleton-header {
    margin: 16px 0 32px 0;
    height: 40px;
}
.skeleton-line{
    height: 50px;
    margin: 10px 0 16px 0;
}
@keyframes shimmer {
    0% {
        background-position: 100% 0;
    }
    60%, 100% {
        background-position: -50% 0;
    }
}

@keyframes jump {
    0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
    50%   {transform: translate3d(0%,50%,0) scale3d(1,.8,1);}
    100% {transform: translate3d(0%,0,0) scale3d(1,1,1);}
  }
  @keyframes jump2 {
    0%   {transform: translate3d(0,50%,0) scale3d(1,1,1);}
    50%   {transform: translate3d(0%,0,0) scale3d(1,.8,1);}
    100% {transform: translate3d(0%,50%,0) scale3d(1,1,1);}
  }

body{
    width: 100%;
    padding-bottom: 24px;
    overflow-y: overlay;
}

body::-webkit-scrollbar{
    width: 12px;
}
body::-webkit-scrollbar-track{
    background-color: transparent;
}
body::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: #abaeab88;
}
body::-webkit-scrollbar-thumb:hover{
    background-color: #79837888;
}